import { render, staticRenderFns } from "./Marketprice.vue?vue&type=template&id=9809419e&scoped=true&"
import script from "./Marketprice.vue?vue&type=script&lang=js&"
export * from "./Marketprice.vue?vue&type=script&lang=js&"
import style0 from "./Marketprice.vue?vue&type=style&index=0&id=9809419e&scoped=true&lang=css&"
import style1 from "./Marketprice.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9809419e",
  null
  
)

export default component.exports