<template>
  <div>
    <div class="bannerpng">同鑫独家精准分析，助你更高投资成就</div>
    <div class="w1400">
      <div class="filter">
        <div class="clearfix leimulist">
          <span
            style="
              width: 70px;
              float: left;
              font-size: 14px;
              height: 30px;
              line-height: 30px;
            "
            >类目：</span
          >
          <div class="leimulista clearfix">
            <span
              v-for="(leimuarr, index) in leimuarrs"
              @click="changes(index, leimuarr.groupId)"
              v-bind:class="{ active: index == current }"
              >{{ leimuarr.groupName }}</span
            >
          </div>
        </div>
        <FilterItem
          title="产品"
          v-if="isReady && cateList.length > 0"
          :id="cateIndex"
          :list="cateList"
          :hasAll="false"
          @onClick="cateClk"
        />
      </div>
      <div class="fengleilist clearfix">
        <div class="flistleft">
          <div v-if="isReady">
            <div class="fleftbody">
              <p>
                <span>{{ filterGooddatas.parentName }}</span>
              </p>
              <Table
                style="margin-top: 30px"
                :columns="columns1"
                :data="filterGooddatas.marketPriceList"
                stripe
                :loading="loading2"
              ></Table>
            </div>
          </div>
          <div class="fleftbody">
            <p><span>我的关注</span></p>
            <Table
              style="margin-top: 30px"
              :columns="columns1"
              :data="mygzdata"
              stripe
              :loading="loading1"
            ></Table>
            <Page
              :total="total"
              :current="page"
              @on-change="changenext"
              show-elevator
              style="margin: 22px 0 20px 0; text-align: right"
            ></Page>
            <router-link :to="'/login'" class="loginbtn" v-show="loginpop"
              >登录后查看我的关注</router-link
            >
          </div>
          <Spin size="large" fix v-show="spinShow">
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            ></Icon>
            <div>加载中...</div>
          </Spin>
        </div>

        <div class="flistright">
          <div class="righttop">
            <p>APP扫码下载</p>
            <img
              src="~assets/img/public/QRcode_app.png"
              style="
                width: 150px;
                height: 150px;
                margin: 0 auto;
                display: block;
              "
            />
            <span>随时随地 关注行情</span>
          </div>
          <div class="rightbottom">
            <p>热门分析</p>
            <div @click.stop="handleImgClick($event)">
              <!--<vue-seamless-scroll :data="fgtjarrs" style="height:412px;overflow: hidden;border: 1px solid #E6EBF5;" :class-option="defaultOption">-->
              <ul
                class="tiaojiabody"
                style="
                  height: 380px;
                  overflow: hidden;
                  border: 1px solid #e6ebf5;
                "
              >
                <li
                  class="clearfix"
                  v-for="(fgtjarr, index) in fgtjarrs"
                  :id="fgtjarr.id"
                  :data-page="fgtjarr.page"
                  :data-subjectId="fgtjarr.subjectId"
                  :data-marketId="fgtjarr.marketId"
                >
                  <span class="fl"></span>[{{ fgtjarr.marketName }}]{{
                    fgtjarr.title
                  }}
                </li>
              </ul>
              <!--</vue-seamless-scroll>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHead from '@/components/content/mainTabbar/NavHead'
import LogoBar from '@/components/content/mainTabbar/LogoBar'
import {
  getGroupApi,
  selectMarketPriceSubscriptionApi,
  selectMarketPriceApi,
  getAuctionNewsApi,
} from 'network/home'
export default {
  name: 'Marketprice',
  data() {
    var self = this // 存储当前vue实例this,这行是重
    return {
      spinShow: true,
      isReady: false,
      cateIndex: 0,
      cateList: [],
      loading1: true,
      loading2: true,
      loginpop: false,
      setVip: '',
      token: '',
      total: 0,
      count: 1, //总页数
      page: 1,
      groupId: '',
      userName: '',
      leimuarrs: [],
      gooddatas: [],
      filterGooddatas: [], // 过滤后的产品表
      current: 0, //行情价格默认第一个
      columns1: [
        {
          renderHeader: (h, params) => {
            return h(
              'span',
              {
                style: {
                  marginLeft: '20px',
                },
              },
              '产品名称'
            )
          },
          key: 'productName',
          align: 'left',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: {
                    display: 'inline-block',
                    height: '100%',
                    color: '#25449a',
                    paddingLeft: '20px',
                    textDecoration: 'underline',
                  },
                  on: {
                    click: () => {
                      if (self.token) {
                        if (this.setVip == 1) {
                          self.$router.push({
                            path: '/marketdetail',
                            query: {
                              productId: params.row.productId,
                              marketId: params.row.marketId,
                              // shopname:1,
                              // branchname:1
                            },
                          })
                        } else {
                          self.$Modal.warning({
                            title: '提示',
                            content:
                              '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
                          })
                        }
                      } else {
                        self.$router.push({ path: '/login' })
                      }
                    },
                  },
                },
                params.row.productName
              ),
            ])
          },
        },
        {
          title: '分类',
          key: 'parentName',
          align: 'center',
        },
        {
          title: '价格',
          key: '',
          align: 'center',
          render: function (h, params) {
            if (self.token && self.setVip == 1) {
              return h('div', [
                h('span', {}, params.row.lprice),
                h('span', {}, '-'),
                h('span', {}, params.row.hprice),
              ])
            } else {
              return h('div', '****')
            }
          },
        },
        {
          title: '涨跌',
          key: 'priceChange',
          align: 'center',
          render: function (h, params) {
            if (self.token && self.setVip == 1) {
              let text = params.row.priceChange
                ? params.row.priceChange.substring(0, 1)
                : ''
              // console.log(params.row.priceChange)
              let texts = params.row.priceChange
                ? params.row.priceChange.substring(
                    1,
                    params.row.priceChange.length
                  )
                : ''
              let num = params.row.priceChange
                ? params.row.priceChange.substring(1)
                : ''
              var imgstyle
              var textstyle
              if (text == '涨') {
                imgstyle = 'up'
                textstyle = 'red'
              } else if (text == '跌') {
                imgstyle = 'down'
                textstyle = 'green'
              }
              if (num == 0) {
                imgstyle = ''
                textstyle = ''
                texts = '平'
              }
              return h('div', [
                h(
                  'span',
                  {
                    style: {},
                    class: [imgstyle],
                  },
                  ''
                ),
                h(
                  'span',
                  {
                    style: {},
                    class: [textstyle],
                  },
                  texts
                ),
              ])
            } else {
              return h('div', '****')
            }
          },
        },
        {
          title: '市场',
          key: 'marketName',
          align: 'center',
        },
        {
          title: '备注',
          key: 'comment',
          align: 'center',
        },
        {
          title: '时间',
          key: '',
          align: 'center',
          render: function (h, params) {
            return h('div', [h('span', {}, params.row.addDate.slice(5, 16))])
          },
        },
        {
          title: '图表',
          key: 'address',
          align: 'center',
          render: function (h, params) {
            return h('div', [
              h(
                'span',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  style: {},
                  class: ['tubiao'],
                  on: {
                    click: function () {
                      if (self.token) {
                        if (self.setVip == 1) {
                          self.$router.push({
                            path: '/marketdetail',
                            query: {
                              productId: params.row.productId,
                              marketId: params.row.marketId,
                              // shopname:1,
                              // branchname:1
                            },
                          })
                        } else {
                          self.$Modal.warning({
                            title: '提示',
                            content:
                              '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
                          })
                        }
                      } else {
                        self.$router.push({ path: '/login' })
                      }
                    },
                  },
                },
                ''
              ),
            ])
          },
        },
      ],
      // MainPage: 1 /* 主页表页数 */,
      // MainSize: 10 /* 主页表一页显示条数 */,
      // MainTotal: 16 /* 主页表数据条数 */,//行情价格表格title
      mygzdata: [],
      fgtjarrs: [], //热门发布
    }
  },
  components: {
    NavHead,
    LogoBar,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.6, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true, // 是否开启鼠标悬停stop
        // direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  created() {
    this.userName = localStorage.getItem('userName')
    this.token = this.$store.state.token
    this.setVip = localStorage.getItem('setVip')
    if (this.token) {
      this.selectMarket()
      this.loginpop = false
      this.loading1 = false
    } else {
      this.loginpop = true
      this.loading1 = false
    }
    this.getGroup()
    this.getAuctionNewsfx_rmfx()
  },
  mounted() {
    // this.selectMarket();
  },
  methods: {
    cateClk(item) {
      this.cateIndex = item.id
      this.filterGooddatas = this.gooddatas[item.id]
    },
    changes(index, groupId) {
      this.cateIndex = 0
      this.current = index
      this.groupId = groupId
      this.selectPrice()
    },
    getGroup() {
      let data = {
        num: 1,
        size: 10,
      }
      getGroupApi(data).then((res) => {
        if (res.code == 0) {
          this.leimuarrs = res.data
          this.groupId = res.data[0].groupId
          this.selectPrice()
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    selectMarket() {
      let data = {
        pageNum: this.page,
        pageSize: 10,
        userName: this.userName,
      }
      selectMarketPriceSubscriptionApi(data).then((res) => {
        if (res.code == 0) {
          this.mygzdata = res.data.list
          this.count = res.data.size
          this.total = parseInt(res.data.total || 0)
          this.loading1 = false
        } else {
          this.loading1 = false
        }
      })
    },
    selectPrice() {
      this.filterGooddatas = []
      this.gooddatas = []
      this.cateList = []
      let data = {
        groupId: this.groupId,
      }
      this.spinShow = true
      selectMarketPriceApi(data).then((res) => {
        this.spinShow = false
        if (res.code == 0) {
          this.gooddatas = res.data
          this.cateList = res.data.map((e, i) => {
            return {
              id: i,
              name: e.parentName,
            }
          })
          this.isReady = true
          this.filterGooddatas = res.data[this.cateIndex]
          this.loading2 = false
        } else {
          // this.$Message.warning(res.errInfo)
          this.loading2 = false
        }
      })
    },
    changenext(page) {
      this.page = page
      this.selectMarket()
    },
    handleImgClick(e) {
      let id = e.target.id
      let page = e.target.dataset.page
      let subjectId = e.target.dataset.subjectid
      let marketId = e.target.dataset.marketid
      if (this.token) {
        let data = {
          num:1,
          size:1,
          productId:14953,
          userName:this.userName,
          id:e.target.id
          // name:1
        }
        getAuctionNewsApi(data).then((res) => {
          if(res.data&&res.data.length>0){
          this.$router.push({
            name: 'analysisDel',
            query: {
              id: id,
              page:page,
              subjectId:subjectId,
              marketId:marketId
            },
          })
        }else{
          this.$Modal.warning({
            title: '提示',
            content:
              '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
          })
        }
      })
      } else {
        this.$Message.warning('请先登录')
        this.$router.push({ path: '/login' })
      }
    },
    getAuctionNewsfx_rmfx() {
      let data = {
        num: 15,
        size: 10,
        productId: 14953,
        // name:1
      }
      getAuctionNewsApi(data).then((res) => {
        if (res.code == 0) {
          this.fgtjarrs = res.data
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
  },
}
</script>

<style scoped>
body {
  color: #000 !important;
}
.loginbtn {
  color: #25449a;
}
.bannerpng {
  width: 100%;
  height: 180px;
  background: url('~assets/img/1920.png');
  background-color: #3d60bb;
  text-align: center;
  line-height: 180px;
  color: #fff;
  font-size: 40px;
  font-family: MicrosoftYaHei;
}
.filter {
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #e6ebf5;
}
.leimulist {
  display: flex;
  margin-bottom: 10px;
}
.leimulist .leimulista span {
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
  float: left;
  line-height: 30px;
}
.leimulist .leimulista span.active {
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #25449a;
  border-radius: 4px;
}
.fengleilist {
  margin-bottom: 60px;
}
.flistleft {
  width: 800px;
  float: left;
  /*position: absolute;*/
}
.flistleft .fleftbody {
  position: relative;
}
.flistleft .fleftbody .loginbtn {
  position: absolute;
  top: 141px;
  left: 50%;
  margin-left: -54px;
  background: #fff;
  font-size: 16px;
  text-decoration: underline;
}
.flistleft .fleftbody p {
  border-bottom: 1px solid #e6ebf5;
  margin-top: 15px;
}
.flistleft .fleftbody p span {
  position: relative;
  top: 1px;
  line-height: 50px;
  border-bottom: 2px solid #25449a;
  display: inline-block;
  font-size: 16px;
  color: #25449a;
}
.flistright {
  width: 350px;
  float: right;
}
.flistright .righttop {
  background: #25449a;
  border-radius: 5px;
  margin-top: 38px;
  overflow: hidden;
}
.flistright .righttop p {
  text-align: center;
  line-height: 84px;
  font-size: 18px;
  color: #fff;
}
.flistright .righttop span {
  display: block;
  font-size: 18px;
  color: #303133;
  line-height: 60px;
  text-align: center;
  background: #d9ebff;
  margin-top: 30px;
}
.flistright .rightbottom {
  margin-top: 23px;
}
.flistright .rightbottom p {
  line-height: 40px;
  background: #e6ebf5;
  border-left: 3px solid #25449a;
  font-size: 15px;
  padding-left: 18px;
}
.tiaojiabody {
  height: 380px;
  padding: 0px 8px;
  font-size: 14px;
  overflow: hidden;
}
.tiaojiabody li {
  line-height: 38px;
  cursor: pointer;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  list-style: disc;
}
.tiaojiabody li:hover {
  color: #25449a;
  text-decoration: underline;
}
.tiaojiabody li span.fl:before {
  content: '.';
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  vertical-align: middle;
  margin-right: 15px;
}
/*.tubiao{*/
/*cursor:pointer;*/
/*display:block;*/
/*width:22px;*/
/*height:20px;*/
/*background: url('~assets/img/tbpng.png');*/
/*margin: 0 auto;*/
/*}*/
</style>
<style>
body {
  color: #000 !important;
}
.ivu-table {
  color: #000 !important;
}
.flistleft .ivu-table th {
  background-color: #e6ebf5;
}
.flistleft .ivu-table-stripe .ivu-table-body tr:nth-child(2n) td,
.flistleft .ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td {
  background-color: #f4f6fb;
}
.tubiao {
  cursor: pointer;
  display: block;
  width: 22px;
  height: 20px;
  background: url('~assets/img/tbpng.png');
  margin: 0 auto;
}
.ivu-table-cell .up {
  display: inline-block;
  width: 10px;
  height: 13px;
  background: url('~assets/img/up.png');
  vertical-align: middle;
  margin-right: 3px;
}
.ivu-table-cell .down {
  display: inline-block;
  width: 10px;
  height: 13px;
  background: url('~assets/img/down.png');
  vertical-align: middle;
  margin-right: 3px;
}
.ivu-table-cell .red {
  color: #d31818;
}
.ivu-table-cell .green {
  color: #31aa24;
}
.ivu-table-cell {
  padding: 0 !important;
}
</style>
